<template>
  <div></div>
</template>
<script setup>
import { logout } from "@/utilities";
/**
 * This is a manual logout page.
 */
logout({ returnTo: `${location.protocol}//${location.host}` });

</script>
